nav {
  &.pagination {
    margin-top: 4rem;
    position: relative;
    text-align: center;
    display: block;
    .page-number {
      display: inline-block;
      color: $light-blue;
    }
    a {
      @extend button;
      &.older-posts {
        position: absolute;
        right: 0;
      }
      &.newer-posts {
        position: absolute;
        left: 0;
      }
    }
  }
}