// bower:scss
@import "bower_components/bourbon/app/assets/stylesheets/_bourbon.scss";
@import "bower_components/neat/app/assets/stylesheets/_neat.scss";
// endbower

@import "base/base";
@import "home";
@import "navigation";
@import "header";
@import "post";
@import "footer";
@import "pagination";

#content {
  @include media(min-width 640px) {
    @include outer-container(93%);
    article {
      @include span-columns(10);
      @include shift(1);
    }
  }
  @include media(max-width 640px) {
    @include outer-container(95%);
    article {
      @include span-columns(12);
    }
  }
  @include media(min-width 1200px) {
    @include outer-container(1200px);
    article {
      @include span-columns(10);
      @include shift(1);
    }
  }
}

