
.centered-navigation {

  ///////////////////////////////////////////////////////////////////////////////////
  $base-border-radius: 3px !default;
  $dark-gray: #333 !default;
  $large-screen: em(860) !default;
  $base-font-color: white !default;
  //////////////////////////////////////////////////////////////////////////////////

  $centered-navigation-padding: 1em;
  $centered-navigation-logo-height: 2em;
  $centered-navigation-background: #FFF;
  $centered-navigation-color: transparentize($base-font-color, 0.3);
  $centered-navigation-color-hover: $base-font-color;
  $centered-navigation-height: 60px;
  $centered-navigation-item-padding: 1em;
  $centered-navigation-submenu-padding: 1em;
  $centered-navigation-submenu-width: 12em;
  $centered-navigation-item-nudge: 2.2em;
  $horizontal-bar-mode: $large-screen;

  background-color: $centered-navigation-background;
  border-bottom: 1px solid $medium-gray;
  min-height: $centered-navigation-height;
  margin-bottom: 4rem;
  width: 100%;
  z-index: 9999;

  // Mobile view
  .centered-navigation-mobile-menu {
    color: $centered-navigation-color;
    display: block;
    // float: right;
    font-weight: 400;
    // line-height: $centered-navigation-height;
    margin: 0;
    padding-right: $centered-navigation-submenu-padding;
    text-decoration: none;
    text-transform: uppercase;

    @include media ($horizontal-bar-mode) {
      display: none;
    }

    &:focus,
    &:hover {
      color: $centered-navigation-color-hover;
    }

    img {
      @include transition(transform .5s ease-in-out);
      height: $centered-navigation-height;
      width: $centered-navigation-height;
      &.rotated {
        @include transform(rotate(180deg));
      }
    }
  }

  // Nav menu

  .centered-navigation-wrapper {
    @include outer-container;
    @include clearfix;
    position: relative;
    z-index: 999;
  }

  ul.centered-navigation-menu {
    -webkit-transform-style: preserve-3d; // stop webkit flicker
    clear: both;
    display: none;
    margin: 0 auto;
    overflow: visible;
    padding: 0;
    width: 100%;
    z-index: 99999;

    &.show {
      display: block;
    }

    @include media ($horizontal-bar-mode) {
      display: block;
      text-align: center;
    }
  }

  // The nav items

  .nav-link:first-child {
    @include media($horizontal-bar-mode) {
      margin-left: $centered-navigation-item-nudge;
    }
  }

  ul li.nav-link {
    background: $centered-navigation-background;
    display: block;
    line-height: $centered-navigation-height;
    overflow: hidden;
    padding-right: $centered-navigation-submenu-padding;
    text-align: center;
    width: 100%;
    z-index: 9999;

    a {
      color: $centered-navigation-color;
      display: inline-block;
      outline: none;
      text-decoration: none;
      font-weight: 300;
      font-size: 2.2rem;
      &:focus,
      &:hover {
        color: $centered-navigation-color-hover;
      }
    }

    @include media($horizontal-bar-mode) {
      background: transparent;
      display: inline;
      line-height: $centered-navigation-height;

      a {
        padding-right: $centered-navigation-item-padding;
      }
    }
  }

  li.logo.nav-link {
    display: none;
    line-height: 0;

    @include media($large-screen) {
      display: inline;
    }
  }

  .logo img {
    margin-bottom: -$centered-navigation-logo-height / 3;
    max-height: $centered-navigation-logo-height;
    opacity: 0.6;
  }

  // Sub menus

  li.more.nav-link {
    padding-right: 0;

    @include media($large-screen) {
      padding-right: $centered-navigation-submenu-padding;
    }

    > ul > li:first-child a  {
      padding-top: 1em;
    }

    a {
      margin-right: $centered-navigation-submenu-padding;
    }

    > a {
      padding-right: 0.6em;
    }

    > a:after {
      @include position(absolute, auto -0.4em auto auto);
      color: $centered-navigation-color;
      content: "\25BE";
    }
  }

  li.more {
    overflow: visible;
    padding-right: 0;

    a {
      padding-right: $centered-navigation-submenu-padding;
    }

    > a {
      padding-right: 1.6em;
      position: relative;

      @include media($large-screen) {
        margin-right: $centered-navigation-submenu-padding;
      }

      &:after {
        content: "›";
        font-size: 1.2rem;
        position: absolute;
        right: $centered-navigation-submenu-padding / 2;
      }
    }

    &:focus > .submenu,
    &:hover > .submenu {
      display: block;
    }

    @include media($horizontal-bar-mode) {
      padding-right: $centered-navigation-submenu-padding;
      position: relative;
    }
  }

  ul.submenu {
    display: none;
    padding-left: 0;

    @include media($horizontal-bar-mode) {
      left: -$centered-navigation-submenu-padding;
      position: absolute;
      top: 1.5em;
    }

    .submenu {
      @include media($horizontal-bar-mode) {
        left: $centered-navigation-submenu-width - 0.2em;
        top: 0;
      }
    }

    li {
      display: block;
      padding-right: 0;

      @include media($horizontal-bar-mode) {
        line-height: $centered-navigation-height / 1.3;

        &:first-child > a {
          border-top-left-radius: $base-border-radius;
          border-top-right-radius: $base-border-radius;
        }

        &:last-child > a {
          border-bottom-left-radius: $base-border-radius;
          border-bottom-right-radius: $base-border-radius;
          padding-bottom: .7em;
        }
      }

      a {
        background-color: darken($centered-navigation-background, 3%);
        display: inline-block;
        text-align: right;
        text-decoration: none;
        width: 100%;

        @include media($horizontal-bar-mode) {
          background-color: $centered-navigation-background;
          padding-left: $centered-navigation-submenu-padding;
          text-align: left;
          width: $centered-navigation-submenu-width;
        }
      }
    }
  }
}
