html {
    height: 100%;
    max-height: 100%;
    font-size: 62.5%;
}

body {
  height: 100%;
  max-height: 100%;
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-size: 1.4rem;
  color: $blue;
}

h1 {
  @include media(min-width 640px) {
    font-size: 4.5rem;
  }
  @include media(max-width 640px) {
    font-size: 3.5rem;
  }
}

h2 {
  @include media(min-width 640px) {
    font-size: 3.5rem;
  }
  @include media(max-width 640px) {
    font-size: 2.5rem;
  }
}

h3 {
  @include media(min-width 640px) {
    font-size: 3rem;
  }
  @include media(max-width 640px) {
    font-size: 2.2rem;
  }
}

h4 {
  @include media(min-width 640px) {
    font-size: 2.5rem;
  }
  @include media(max-width 640px) {
    font-size: 2rem;
  }
}

h5, h6 {
  @include media(min-width 640px) {
    font-size: 2rem;
  }
  @include media(max-width 640px) {
    font-size: 1.6rem;
  }
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color 0.1s linear;

  &:active,
  &:focus,
  &:hover {
    color: darken($action-color, 15%);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}
