#{$all-button-inputs},
button {
  @include appearance(none);
  -webkit-font-smoothing: antialiased;
  background-color: #FFF;
  border-radius: $base-border-radius;
  border: 1px solid $light-gray;
  color: $dark-blue;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 300;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: darken($action-color, 15%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
