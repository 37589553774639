article {
  &.post {
    h1,h2,h3 {
      font-weight: 300;
    }
    p, ul, ol {
      font-family: 'Open Sans', sans-serif;
      @include media(min-width 640px) {
        font-size: 1.8rem;
        line-height: 2.7rem;
      }
      @include media(max-width 640px) {
        font-size: 1.6rem;
        line-height: 2.3rem;
      }
      font-weight: 300;
      font-variant: normal;
    }
    img {
      margin: 2.3rem 0;
      border: 1px solid $blue;
      padding: 0.2rem;
    }
    a {
      color: $dark-blue;
      border-bottom: 1px solid $light-blue;
    }
  }
}

footer {
  &.post-footer {
    position: relative;
    margin-top: 3rem;
    border-top: 1px solid $light-blue;
  }
}

// ======================================================================================
// === Post Image
// ======================================================================================

.image-gradient-dynamic {

  $image-gradient-color-top: black; 
  $transparency-top: .8;
  $image-gradient-color-bottom: black; 
  $transparency-bottom: .8;
  $vertical-angle: 0deg;
  
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  line-height: 0;

  img {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
  }

  .image-text {

    @include media(max-width 800px) {
          display: none;
    }
    @include transform(translate(-50%, -50%));
    left: 50%;
    margin: auto;
    position: absolute;
    text-align: center;
    bottom: 5%;
    z-index: 999;

    p {
      color: white;
      font-weight: 300;
      font-size: 2.3rem;
      line-height: 2.3rem;
      padding: 1em 2em;
      position: relative;
    }
  }

  .overlay {
    @include linear-gradient($vertical-angle, transparentize($image-gradient-color-top, $transparency-top), 
      transparentize($image-gradient-color-bottom, $transparency-bottom));
    display: block;
    @include position(absolute, 0px 0px 0px 0px);
    max-width: 100%;
    margin: 0;
    padding: 0;
    z-index: 998;
  }
}

