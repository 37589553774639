header {
  padding: 0.5em 1em;
  text-align: center;
  
  .wrapper {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    color: #fff;
    font-weight: 300;
    line-height: 1.618em;
  }
}

.main-header {
  $main-header-background: #FFF;
  $main-header-font-color: $dark-blue;
  background-color: $main-header-background;
  border-bottom: 1px solid $medium-gray;
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: $main-header-font-color;
  }
  p {
    color: $dark-gray;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
  }
}


