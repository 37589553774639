@charset "UTF-8";
html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

button, nav.pagination a, input[type="button"], input[type="reset"], input[type="submit"],
button,
nav.pagination a {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  background-color: #FFF;
  border-radius: 3px;
  border: 1px solid #ddd;
  color: #1E2F40;
  cursor: pointer;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 12pt;
  font-weight: 300;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, nav.pagination a:hover, button:focus, nav.pagination a:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus,
  button:hover,
  nav.pagination a:hover,
  button:focus,
  nav.pagination a:focus {
    background-color: #06090c;
    color: #fff; }
  button:disabled, nav.pagination a:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled,
  button:disabled,
  nav.pagination a:disabled {
    cursor: not-allowed;
    opacity: 0.5; }

fieldset {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin: 0 0 0.75em;
  padding: 1.5em; }

input,
label,
select {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 12pt; }

label {
  font-weight: 600;
  margin-bottom: 0.375em; }
  label.required::after {
    content: "*"; }
  label abbr {
    display: none; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea,
select[multiple=multiple],
textarea {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-size: 12pt;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover,
  select[multiple=multiple]:hover,
  textarea:hover {
    border-color: #c4c4c4; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus,
  select[multiple=multiple]:focus,
  textarea:focus {
    border-color: #1E2F40;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(22, 34, 47, 0.7);
    outline: none; }

textarea {
  resize: vertical; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none; }

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

input[type="file"] {
  padding-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 1.5em;
  max-width: 100%;
  width: auto; }

dl {
  margin-bottom: 0.75em; }
  dl dt {
    font-weight: bold;
    margin-top: 0.75em; }
  dl dd {
    margin: 0; }

table {
  -webkit-font-feature-settings: "kern", "liga", "tnum";
  -moz-font-feature-settings: "kern", "liga", "tnum";
  -ms-font-feature-settings: "kern", "liga", "tnum";
  font-feature-settings: "kern", "liga", "tnum";
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #b7b7b7;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

html {
  height: 100%;
  max-height: 100%;
  font-size: 62.5%; }

body {
  height: 100%;
  max-height: 100%;
  -webkit-font-feature-settings: "kern", "liga", "pnum";
  -moz-font-feature-settings: "kern", "liga", "pnum";
  -ms-font-feature-settings: "kern", "liga", "pnum";
  font-feature-settings: "kern", "liga", "pnum";
  -webkit-font-smoothing: antialiased;
  color: #333;
  font-family: "Lato", sans-serif;
  font-size: 12pt;
  line-height: 1.5;
  font-size: 1.4rem;
  color: #2B445C; }

@media screen and (min-width: 640px) {
  h1 {
    font-size: 4.5rem; } }

@media screen and (max-width: 640px) {
  h1 {
    font-size: 3.5rem; } }

@media screen and (min-width: 640px) {
  h2 {
    font-size: 3.5rem; } }

@media screen and (max-width: 640px) {
  h2 {
    font-size: 2.5rem; } }

@media screen and (min-width: 640px) {
  h3 {
    font-size: 3rem; } }

@media screen and (max-width: 640px) {
  h3 {
    font-size: 2.2rem; } }

@media screen and (min-width: 640px) {
  h4 {
    font-size: 2.5rem; } }

@media screen and (max-width: 640px) {
  h4 {
    font-size: 2rem; } }

@media screen and (min-width: 640px) {
  h5, h6 {
    font-size: 2rem; } }

@media screen and (max-width: 640px) {
  h5, h6 {
    font-size: 1.6rem; } }

p {
  margin: 0 0 0.75em; }

a {
  color: #1E2F40;
  text-decoration: none;
  transition: color 0.1s linear; }
  a:active, a:focus, a:hover {
    color: #06090c; }
  a:active, a:focus {
    outline: none; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

.post-boxes {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  box-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  -o-align-items: stretch;
  align-items: stretch;
  -ms-flex-align: stretch;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  clear: both;
  text-align: center; }
  .post-boxes a {
    text-decoration: none; }
  .post-boxes::after {
    clear: both;
    content: "";
    display: table; }
  .post-boxes .post-box {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-box-flex: 2;
    -moz-box-flex: 2;
    box-flex: 2;
    -webkit-flex: 2 2 15em;
    -moz-flex: 2 2 15em;
    -ms-flex: 2 2 15em;
    flex: 2 2 15em;
    -webkit-align-self: stretch;
    -moz-align-self: stretch;
    align-self: stretch;
    -ms-flex-item-align: stretch;
    background: #f7f7f7;
    border-radius: 3px;
    box-shadow: inset 0 0 1px #ddd, 0 1px 2px #e6e6e6;
    display: block;
    margin: 0.4em;
    padding: 2em 2em 3em 2em; }
    .post-boxes .post-box:nth-child(1) {
      border-top: 1px solid #7F1637;
      border-bottom: 1px solid #7F1637; }
      .post-boxes .post-box:nth-child(1):focus, .post-boxes .post-box:nth-child(1):hover {
        background-color: rgba(127, 22, 55, 0.2); }
    .post-boxes .post-box:nth-child(2) {
      border-top: 1px solid #047878;
      border-bottom: 1px solid #047878; }
      .post-boxes .post-box:nth-child(2):focus, .post-boxes .post-box:nth-child(2):hover {
        background-color: rgba(4, 120, 120, 0.2); }
    .post-boxes .post-box:nth-child(3) {
      border-top: 1px solid #FFB733;
      border-bottom: 1px solid #FFB733; }
      .post-boxes .post-box:nth-child(3):focus, .post-boxes .post-box:nth-child(3):hover {
        background-color: rgba(255, 183, 51, 0.2); }
    .post-boxes .post-box:nth-child(4) {
      border-top: 1px solid #F57336;
      border-bottom: 1px solid #F57336; }
      .post-boxes .post-box:nth-child(4):focus, .post-boxes .post-box:nth-child(4):hover {
        background-color: rgba(245, 115, 54, 0.2); }
    .post-boxes .post-box:nth-child(5) {
      border-top: 1px solid #C22121;
      border-bottom: 1px solid #C22121; }
      .post-boxes .post-box:nth-child(5):focus, .post-boxes .post-box:nth-child(5):hover {
        background-color: rgba(194, 33, 33, 0.2); }
    .post-boxes .post-box:nth-child(6) {
      border-top: 1px solid #2C3E50;
      border-bottom: 1px solid #2C3E50; }
      .post-boxes .post-box:nth-child(6):focus, .post-boxes .post-box:nth-child(6):hover {
        background-color: rgba(44, 62, 80, 0.2); }
    .post-boxes .post-box:nth-child(7) {
      border-top: 1px solid #E74C3C;
      border-bottom: 1px solid #E74C3C; }
      .post-boxes .post-box:nth-child(7):focus, .post-boxes .post-box:nth-child(7):hover {
        background-color: rgba(231, 76, 60, 0.2); }
    .post-boxes .post-box:nth-child(8) {
      border-top: 1px solid #5C832F;
      border-bottom: 1px solid #5C832F; }
      .post-boxes .post-box:nth-child(8):focus, .post-boxes .post-box:nth-child(8):hover {
        background-color: rgba(92, 131, 47, 0.2); }
    .post-boxes .post-box:nth-child(9) {
      border-top: 1px solid #3498DB;
      border-bottom: 1px solid #3498DB; }
      .post-boxes .post-box:nth-child(9):focus, .post-boxes .post-box:nth-child(9):hover {
        background-color: rgba(52, 152, 219, 0.2); }
    .post-boxes .post-box:nth-child(10) {
      border-top: 1px solid #E15F14;
      border-bottom: 1px solid #E15F14; }
      .post-boxes .post-box:nth-child(10):focus, .post-boxes .post-box:nth-child(10):hover {
        background-color: rgba(225, 95, 20, 0.2); }
    .post-boxes .post-box .post-title {
      color: rgba(51, 51, 51, 0.7);
      font-size: 2.2rem;
      font-weight: 400;
      margin-bottom: 0.5em; }
    .post-boxes .post-box p {
      color: rgba(51, 51, 51, 0.6);
      line-height: 1.5em;
      margin: auto; }
  .post-boxes .post-box-big {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 40em;
    -moz-flex: 1 1 40em;
    -ms-flex: 1 1 40em;
    flex: 1 1 40em; }

.centered-navigation {
  background-color: #FFF;
  border-bottom: 1px solid #999;
  min-height: 60px;
  margin-bottom: 4rem;
  width: 100%;
  z-index: 9999; }
  .centered-navigation .centered-navigation-mobile-menu {
    color: rgba(51, 51, 51, 0.7);
    display: block;
    font-weight: 400;
    margin: 0;
    padding-right: 1em;
    text-decoration: none;
    text-transform: uppercase; }
    @media screen and (min-width: 53.75em) {
      .centered-navigation .centered-navigation-mobile-menu {
        display: none; } }
    .centered-navigation .centered-navigation-mobile-menu:focus, .centered-navigation .centered-navigation-mobile-menu:hover {
      color: #333; }
    .centered-navigation .centered-navigation-mobile-menu img {
      -webkit-transition: -webkit-transform;
      -moz-transition: -moz-transform;
      transition: transform;
      height: 60px;
      width: 60px; }
      .centered-navigation .centered-navigation-mobile-menu img.rotated {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg); }
  .centered-navigation .centered-navigation-wrapper {
    max-width: 68em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 999; }
    .centered-navigation .centered-navigation-wrapper::after {
      clear: both;
      content: "";
      display: table; }
    .centered-navigation .centered-navigation-wrapper::after {
      clear: both;
      content: "";
      display: table; }
  .centered-navigation ul.centered-navigation-menu {
    -webkit-transform-style: preserve-3d;
    clear: both;
    display: none;
    margin: 0 auto;
    overflow: visible;
    padding: 0;
    width: 100%;
    z-index: 99999; }
    .centered-navigation ul.centered-navigation-menu.show {
      display: block; }
    @media screen and (min-width: 53.75em) {
      .centered-navigation ul.centered-navigation-menu {
        display: block;
        text-align: center; } }
  @media screen and (min-width: 53.75em) {
    .centered-navigation .nav-link:first-child {
      margin-left: 2.2em; } }
  .centered-navigation ul li.nav-link {
    background: #FFF;
    display: block;
    line-height: 60px;
    overflow: hidden;
    padding-right: 1em;
    text-align: center;
    width: 100%;
    z-index: 9999; }
    .centered-navigation ul li.nav-link a {
      color: rgba(51, 51, 51, 0.7);
      display: inline-block;
      outline: none;
      text-decoration: none;
      font-weight: 300;
      font-size: 2.2rem; }
      .centered-navigation ul li.nav-link a:focus, .centered-navigation ul li.nav-link a:hover {
        color: #333; }
    @media screen and (min-width: 53.75em) {
      .centered-navigation ul li.nav-link {
        background: transparent;
        display: inline;
        line-height: 60px; }
        .centered-navigation ul li.nav-link a {
          padding-right: 1em; } }
  .centered-navigation li.logo.nav-link {
    display: none;
    line-height: 0; }
    @media screen and (min-width: 53.75em) {
      .centered-navigation li.logo.nav-link {
        display: inline; } }
  .centered-navigation .logo img {
    margin-bottom: -0.6666666667em;
    max-height: 2em;
    opacity: 0.6; }
  .centered-navigation li.more.nav-link {
    padding-right: 0; }
    @media screen and (min-width: 53.75em) {
      .centered-navigation li.more.nav-link {
        padding-right: 1em; } }
    .centered-navigation li.more.nav-link > ul > li:first-child a {
      padding-top: 1em; }
    .centered-navigation li.more.nav-link a {
      margin-right: 1em; }
    .centered-navigation li.more.nav-link > a {
      padding-right: 0.6em; }
    .centered-navigation li.more.nav-link > a:after {
      position: absolute;
      top: auto;
      right: -0.4em;
      bottom: auto;
      left: auto;
      color: rgba(51, 51, 51, 0.7);
      content: "\25BE"; }
  .centered-navigation li.more {
    overflow: visible;
    padding-right: 0; }
    .centered-navigation li.more a {
      padding-right: 1em; }
    .centered-navigation li.more > a {
      padding-right: 1.6em;
      position: relative; }
      @media screen and (min-width: 53.75em) {
        .centered-navigation li.more > a {
          margin-right: 1em; } }
      .centered-navigation li.more > a:after {
        content: "›";
        font-size: 1.2rem;
        position: absolute;
        right: 0.5em; }
    .centered-navigation li.more:focus > .submenu,
    .centered-navigation li.more:hover > .submenu {
      display: block; }
    @media screen and (min-width: 53.75em) {
      .centered-navigation li.more {
        padding-right: 1em;
        position: relative; } }
  .centered-navigation ul.submenu {
    display: none;
    padding-left: 0; }
    @media screen and (min-width: 53.75em) {
      .centered-navigation ul.submenu {
        left: -1em;
        position: absolute;
        top: 1.5em; } }
    @media screen and (min-width: 53.75em) {
      .centered-navigation ul.submenu .submenu {
        left: 11.8em;
        top: 0; } }
    .centered-navigation ul.submenu li {
      display: block;
      padding-right: 0; }
      @media screen and (min-width: 53.75em) {
        .centered-navigation ul.submenu li {
          line-height: 46.1538461538px; }
          .centered-navigation ul.submenu li:first-child > a {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px; }
          .centered-navigation ul.submenu li:last-child > a {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            padding-bottom: .7em; } }
      .centered-navigation ul.submenu li a {
        background-color: #f7f7f7;
        display: inline-block;
        text-align: right;
        text-decoration: none;
        width: 100%; }
        @media screen and (min-width: 53.75em) {
          .centered-navigation ul.submenu li a {
            background-color: #FFF;
            padding-left: 1em;
            text-align: left;
            width: 12em; } }

header {
  padding: 0.5em 1em;
  text-align: center; }
  header .wrapper {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto; }
  header h1 {
    color: #fff;
    font-weight: 300;
    line-height: 1.618em; }

.main-header {
  background-color: #FFF;
  border-bottom: 1px solid #999; }
  .main-header h1 {
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: #1E2F40; }
  .main-header p {
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 1.5rem; }

article.post h1, article.post h2, article.post h3 {
  font-weight: 300; }

article.post p, article.post ul, article.post ol {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-variant: normal; }
  @media screen and (min-width: 640px) {
    article.post p, article.post ul, article.post ol {
      font-size: 1.8rem;
      line-height: 2.7rem; } }
  @media screen and (max-width: 640px) {
    article.post p, article.post ul, article.post ol {
      font-size: 1.6rem;
      line-height: 2.3rem; } }

article.post img {
  margin: 2.3rem 0;
  border: 1px solid #2B445C;
  padding: 0.2rem; }

article.post a {
  color: #1E2F40;
  border-bottom: 1px solid #3C5F7F; }

footer.post-footer {
  position: relative;
  margin-top: 3rem;
  border-top: 1px solid #3C5F7F; }

.image-gradient-dynamic {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  line-height: 0; }
  .image-gradient-dynamic img {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important; }
  .image-gradient-dynamic .image-text {
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    margin: auto;
    position: absolute;
    text-align: center;
    bottom: 5%;
    z-index: 999; }
    @media screen and (max-width: 800px) {
      .image-gradient-dynamic .image-text {
        display: none; } }
    .image-gradient-dynamic .image-text p {
      color: white;
      font-weight: 300;
      font-size: 2.3rem;
      line-height: 2.3rem;
      padding: 1em 2em;
      position: relative; }
  .image-gradient-dynamic .overlay {
    background-color: rgba(0, 0, 0, 0.2);
    background-image: -webkit-linear-gradient(-270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    background-image: linear-gradient(0deg,rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    max-width: 100%;
    margin: 0;
    padding: 0;
    z-index: 998; }

footer.main-footer {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #ddd;
  padding-top: 2rem;
  margin-top: 4rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.1rem;
  text-align: center;
  color: #999; }
  footer.main-footer::after {
    clear: both;
    content: "";
    display: table; }
  footer.main-footer a {
    color: #999;
    border-bottom: 1px dotted #999; }
  footer.main-footer .copyright {
    margin-bottom: 0.5rem; }

nav.pagination {
  margin-top: 4rem;
  position: relative;
  text-align: center;
  display: block; }
  nav.pagination .page-number {
    display: inline-block;
    color: #3C5F7F; }
  nav.pagination a.older-posts {
    position: absolute;
    right: 0; }
  nav.pagination a.newer-posts {
    position: absolute;
    left: 0; }

@media screen and (min-width: 640px) {
  #content {
    max-width: 93%;
    margin-left: auto;
    margin-right: auto; }
    #content::after {
      clear: both;
      content: "";
      display: table; }
    #content article {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 82.9403914003%;
      margin-left: 8.5298042998%; }
      #content article:last-child {
        margin-right: 0; } }

@media screen and (max-width: 640px) {
  #content {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto; }
    #content::after {
      clear: both;
      content: "";
      display: table; }
    #content article {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 100%; }
      #content article:last-child {
        margin-right: 0; } }

@media screen and (min-width: 1200px) {
  #content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
    #content::after {
      clear: both;
      content: "";
      display: table; }
    #content article {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 82.9403914003%;
      margin-left: 8.5298042998%; }
      #content article:last-child {
        margin-right: 0; } }
